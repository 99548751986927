<template>
    <div class="faq">
        <div class="page-header">
            <StackRouterHeaderBar />
            <div class="title" v-html="'무엇을 도와드릴까요?'" />
        </div>
        <div class="body">
            <div :key="category.id" v-for="category in categories" class="category">
                <div class="category-name" v-html="category.name" />
                <ul>
                    <li
                        :key="question.id"
                        v-for="question in selectedQuestions(category.id)"
                        class="item"
                        @click="question.$$expanded = !question.$$expanded"
                    >
                        <div class="question flex-row">
                            <div class="q c-primary flex-wrap m-r-8" v-html="'Q'" />
                            <div class="text" v-html="question.question" />
                            <i
                                class="material-icons flex-wrap"
                                :class="{ 'c-primary': question.$$expanded }"
                                v-html="question.$$expanded ? 'expand_less' : 'expand_more'"
                            />
                        </div>
                        <TransitionExpand>
                            <div v-if="question.$$expanded" class="answer" v-html="question.answer" />
                        </TransitionExpand>
                    </li>
                </ul>
                <div class="hr" />
            </div>
        </div>
    </div>
</template>

<script>
import centerService from '@/services/center'

export default {
    name: 'FAQPage',
    data: () => ({
        categories: [],
        questions: [],
    }),
    mounted() {
        this.initFAQ()
    },
    methods: {
        selectedQuestions(categoryId) {
            return this.questions.filter(q => q.category_id === categoryId)
        },
        async initFAQ() {
            try {
                const res = await centerService.faqInfo()
                this.categories = res.faq_categories
                this.questions = res.faq_questions.map(o => {
                    this.$set(o, '$$expanded', false)
                    return o
                })
            } catch (e) {
                this.$toast.error(e)
                this.$router.go(-1)
            }
        },
    },
}
</script>
